import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";
import {Header,  Footer } from "../../templates";
import {useLang} from "../../../helpers/context/LanguageProvider";
import { Heading } from "../../atoms";


const NotFound = () => {
  const {language, translate} = useLang();
  return (
    <>
      <ReactHelmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="KomandaPL" content={translate.mainPage.banner.title[language.name]} />
        <meta name="description" content={translate.seo.description[language.name]} />
        <meta name="keywords" content={translate.seo.keywords[language.name]} />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="robots" content="noindex,nofollow" />
        <title>Komanda praca</title>
      </ReactHelmet>
      <div>
        <div>
          <section className="header--section">
              <Header />
          </section>
          <section className="not-found--section">
              <Heading text={`${translate.notFound[language.name]} 😥`} />
          </section>
          <section className="footer--section">
            <Footer />
          </section>
        </div>
      </div>
    </>


  )
}

export default NotFound;